<template>
  <div>
    <PageHeader :title="`Commissielijst ${commissionId}`">
      <template #buttons>
        <span
          v-if="commission.status === 2"
          title="De commissielijst is gepubliceerd"
          class="tw-px-4 tw-py-1.5 tw-my-4 tw-font-semibold md:tw-text-sm tw-text-white tw-bg-primary tw-rounded"
        >
          <i class="far fa-check tw-mr-1" /> Gepubliceerd
        </span>
        <FormulateInput
          v-if="commission.status === 1 && isStaff"
          type="button"
          :input-class="['tw-h-8 tw-px-4 tw-font-semibold md:tw-text-sm']"
          @click="publish(commissionId)"
        >
          <i class="far fa-clock tw-mr-1" /> Publiceren
        </FormulateInput>
        <FormulateInput
          v-if="commission.status === 2 || isStaff"
          type="button"
          :input-class="['tw-h-8 tw-px-4 tw-ml-2 tw-font-semibold md:tw-text-sm']"
          @click="showListModal"
        >
          <i class="far fa-print tw-mr-1" /> Afdrukken
        </FormulateInput>
      </template>
    </PageHeader>
    <div class="tw-px-2.5 tw-pt-5 tw-pb-16 tw-mx-auto">
      <div class="tw-mb-8 tw-py-6 tw-px-8 tw-grid md:tw-grid-flow-col tw-gap-2 tw-bg-white tw-rounded tw-shadow-lg">
        <div>
          <h4>Medewerker</h4>
          <template v-if="commission.collaborator">
            {{ commission.collaborator.first_name }} {{ commission.collaborator.last_name }}
          </template>
          <template v-else>-</template>
        </div>
        <div>
          <h4>Publicatie datum</h4>
          {{ formatDateForLocale(commission.ends_at) }}
        </div>
        <div>
          <h4>Aangemaakt</h4>
          {{ formatDateForLocale(commission.created_at) }}
        </div>
        <div>
          <h4>Updatedatum</h4>
          {{ formatDateForLocale(commission.updated_at) }}
        </div>
        <div>
          <h4>Totaalbedrag</h4>
          {{ currency(commission.total_amount) }}
        </div>
        <div>
          <h4>Status</h4>
          {{ statusOptions[commission.status] }}
        </div>
      </div>
      <DataTable
        :headers="headers"
        v-bind="commissionLines"
        :paginate="true"
        @changePage="fetchCommissionLines"
      >
        <template #toolbar>
          <button
            v-if="commission.status === 1"
            type="button"
            class="tw-mb-2 action tw-bg-primary"
            @click="show"
          >
            <i class="fa fa-plus"/>
            Nieuw
          </button>
        </template>
        <template #item-amount="{ item }">
          {{ currency(item.amount) }}
        </template>
        <template #item-transaction="{ item }">
          <a :href="`/transaction/view/${item.transaction.id}`" :title="`View ${item.reference}`" v-if="item.transaction && item.transaction.id">
            {{ item.transaction.reference }}
          </a>
        </template>
        <template #item-actions="{ item }">
          <button
            v-if="isPending && !isTransactionLine(item) && isStaff"
            title="Commissieregel verwijderen"
            class="action tw-bg-error"
            @click="removeCommissionLine(item.line_list, item.id)"
          >
            <i class="far fa-trash-alt"/>
          </button>
        </template>
      </DataTable>
    </div>
    <FormulateForm
      #default="{ isLoading }"
      v-model="commissionLine"
      name="createCommissionLine"
      invalid-message="Gelieve de verplichte velden correct in te vullen."
      @submit="createCommissionLine"
    >
      <Modal ref="modal" title="Nieuwe commissieregel" @hide="commissionLineForm = false">
        <template v-if="commissionLineForm">
          <div class="tw-grid tw-grid-cols-2 tw-gap-4">
            <FormulateInput
              type="select"
              name="line_type"
              label="Type"
              :options="commissionLineTypes"
              :show-required-label="true"
              validation="required"
            />
            <FormulateInput
              type="number"
              name="amount"
              step="0.01"
              value="0.0"
              lang="nl"
              label="Bedrag"
              :show-required-label="true"
              validation="bail|required|number"
              data-lpignore="true"
            />
          </div>
          <FormulateInput
            type="textarea"
            name="description"
            label="Omschrijving"
            :show-required-label="true"
            validation="required"
          />
        </template>
        <template #footer>
          <FormulateErrors />
          <div class="tw-flex tw-flex-row tw-space-x-4 tw-justify-end">
            <FormulateInput
              type="submit"
              :disabled="isLoading"
              :input-class="['tw-w-full']"
              :outer-class="['tw-w-full md:tw-w-auto']"
            >
              <i
                :class="[
                  'fas tw-mr-1',
                  isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
                ]"
              />
              Opslaan
            </FormulateInput>
            <FormulateInput
              type="button"
              data-dismiss="modal"
              :input-class="['tw-bg-error tw-w-full']"
              :outer-class="['tw-w-full md:tw-w-auto']"
            >
              <i class="fa fa-times tw-mr-2"/> Annuleren
            </FormulateInput>
          </div>
        </template>
      </Modal>
    </FormulateForm>
    <CommissionListExportModal
      ref="listExportModal"
      :templateType="listTemplateType"
      :exportData="exportData"
      :title="'Commissielijst afdrukken'"
      :defaultListName="'Commissielijst'"
      :landscape="true"
    />
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader.vue'
import DataTable from '@/components/DataTable'
import Modal from '@/components/iam/Modal'
import CommissionListExportModal from '@/components/iam/CommissionListExportModal'

import {
  getCommissionListById,
  publishCommissionList,
  getCommissionLines,
  createCommissionLine,
  deleteCommissionLine,
  getCommissionLineTypes
} from '@/services/commission'
import { currency, formatDateForLocale } from '@/utils/helpers'
import { startLoadingModal, successModal, questionModal, errorModal } from '@/modalMessages'
import { mapState } from 'vuex'

export default {
  name: 'CommissionList',
  components: {
    PageHeader,
    DataTable,
    Modal,
    CommissionListExportModal
  },
  data () {
    return {
      commission: {},
      // The commissionLines includes keys to all the props that are needed in the DataTable, hence, we can use v-bind directly for clean code
      commissionLines: {
        count: null,
        next: null,
        previous: null,
        results: []
      },
      commissionLineForm: false, // Used to kill the form post closing of the modal so that the values don't carry over to the next form open
      commissionLine: {},
      commissionLineTypes: [],
      listTemplateType: 5,
      exportData: {}
    }
  },
  computed: {
    ...mapState('account', ['user']),
    isPending () {
      return this.commission?.status === 1
    },
    isStaff () {
      return this.user.is_staff
    },
    headers () {
      return [
        { text: 'Type', value: 'line_type.name' },
        { text: 'Commissie', value: 'amount' },
        { text: 'Transactie', value: 'transaction' },
        { text: 'Omschrijving', value: 'description' },
        { text: 'Actie', value: 'actions' }
      ]
    },
    statusOptions () {
      return {
        1: 'Pending',
        2: 'Published'
      }
    },
    commissionId () {
      return this.$route.params.id
    }
  },
  created () {
    this.init()
  },
  methods: {
    currency,
    formatDateForLocale,

    isTransactionLine (line) {
      return line.line_type.id === 1
    },
    show () {
      this.commissionLineForm = true
      this.$refs.modal.show()
    },
    hide () {
      this.$refs.modal.hide()
    },
    async init () {
      const payload = {
        commissionId: this.commissionId
      }
      await Promise.all([
        this.fetchCommissionList(this.commissionId),
        this.fetchCommissionLines(payload),
        this.fetchCommissionLineTypes()
      ])
    },
    async fetchCommissionList (commissionId) {
      try {
        const response = await getCommissionListById(commissionId)
        this.commission = response.data
        return response
      } catch (error) {
        console.error(error)
        errorModal('Fout bij laden van commissielijst, probeer het opnieuw.')
      }
    },
    async buildLinesForExport () {
      try {
        const lines = []
        const payload = { commissionId: this.commissionId, params: { page_size: this.commissionLines.count } }
        const response = await getCommissionLines(payload)
        const results = response?.data?.results
        results.forEach(line => {
          const { amount, description, line_type, transaction } = line
          lines.push({
            amount,
            description,
            line_type,
            transaction
          })
        })
        return lines
      } catch (error) {
        console.error(error)
        errorModal('Fout bij laden van commissielijnen')
      }
    },
    async showListModal () {
      const lines = await this.buildLinesForExport()
      this.exportData = {
        id: this.commission?.id,
        collaborator: {
          first_name: this.commission?.collaborator?.first_name,
          last_name: this.commission?.collaborator?.last_name
        },
        starts_at: this.commission?.starts_at,
        ends_at: this.commission?.ends_at,
        total_amount: this.commission?.total_amount,
        lines: lines
      }
      this.$refs.listExportModal.show()
    },
    async fetchCommissionLines (payload) {
      try {
        const response = await getCommissionLines(payload)
        this.commissionLines = response.data
        return response
      } catch (error) {
        console.error(error)
        errorModal('Fout bij het laden van commissieregels, probeer het opnieuw.')
      }
    },
    async fetchCommissionLineTypes () {
      try {
        const response = await getCommissionLineTypes()
        const types = [...response?.data?.results]
        this.commissionLineTypes = types.map(type => {
          return { value: type.id, label: type.name }
        })
        return response
      } catch (error) {
        console.error(error)
        errorModal('Fout bij het laden van commissie types, probeer het opnieuw.')
      }
    },
    async removeCommissionLine (commissionId, lineId) {
      try {
        const result = await questionModal('Weet je zeker dat je deze commissieregel wilt verwijderen?')
        if (!result.value) return
        startLoadingModal('De commissieregel verwijderen...')
        await deleteCommissionLine(commissionId, lineId)
        await this.fetchCommissionLines({ commissionId: this.commissionId })
        successModal('De commissieregel verwijderd')
      } catch (error) {
        console.error(error)
        errorModal('Fout bij het verwijderen van de commissieregel, probeer het opnieuw.')
      }
    },
    async publish (commissionId) {
      try {
        const result = await questionModal('Weet je zeker dat je deze commissielijst wil publiceren')
        if (!result.value) return
        startLoadingModal('De commissielijst publiceren...')
        await publishCommissionList(commissionId)
        await this.fetchCommissionList(commissionId)
        successModal('Commissielijst gepubliceerd', true)
      } catch (error) {
        console.error(error)
        errorModal('Fout bij het publiceren van de commissielijst, probeer het opnieuw.')
      }
    },
    async createCommissionLine (values) {
      try {
        startLoadingModal('Bezig met aanmaken van commisieregel...')
        const response = await createCommissionLine(this.commissionId, values)
        await Promise.all([
          this.fetchCommissionList(this.commissionId),
          this.fetchCommissionLines({ commissionId: this.commissionId })
        ])
        this.hide()
        successModal('De commisieregel is aangemaakt', true)
        return response
      } catch (error) {
        console.error(error)
        errorModal('Fout bij het maken van commissieregel, probeer het opnieuw.')
      }
    }
  }
}
</script>

<style scoped>
h4 {
  @apply tw-font-bold
}
</style>
