import { httpClient } from './axiosClients'

// We provide an empty object as default value to payload because we de-structure it later on
export function getCommissionLists (payload = {}) {
  const { url = '/api/commissions/lists', params } = payload
  return httpClient.get(url, { params })
}

export function getCommissionListById (commissionId) {
  return httpClient.get(`/api/commissions/lists/${commissionId}`)
}

export function publishCommissionList (commissionId) {
  return httpClient.post(`/api/commissions/lists/${commissionId}/publish`)
}

export function getCommissionLines (payload = {}) {
  const { commissionId, url = `/api/commissions/lists/${commissionId}/lines`, params } = payload
  return httpClient.get(url, { params })
}

export function getCommissionLineTypes (params = {}) {
  return httpClient.get('/api/commissions/line-types', { params: { page_size: 100, ...params } })
}

export function createCommissionLine (commissionId, payload) {
  return httpClient.post(`/api/commissions/lists/${commissionId}/lines`, payload)
}

export function deleteCommissionLine (commissionId, lineId) {
  return httpClient.delete(`/api/commissions/lists/${commissionId}/lines/${lineId}`)
}

export function getTransactions (payload = {}) {
  const { url = '/api/commissions/transactions', params } = payload
  return httpClient.get(url, { params })
}

export function getTransactions2 (payload = {}) {
  const { url = '/api/commissions/transactions-2', params } = payload
  return httpClient.get(url, { params })
}

export function getTransactionApprovals (payload = {}) {
  const { transactionId, url = `/api/transactions/${transactionId}/approvals`, params } = payload
  return httpClient.get(url, { params })
}
