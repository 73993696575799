<template>
  <Modal ref="modal" :title="title">
    <div v-if="useCustomListName" class="row form-group">
      <div class="col-md-2"><label class="control-label">Titel lijst</label></div>
      <div class="col-md-10">
        <input class="form-control" type="text" v-model="listName">
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-2"><label class="control-label">Template</label></div>
      <div class="col-md-10">
        <select class="form-control" v-model="selectedTemplate">
          <option value="0" disabled selected>Kies een template</option>
          <option v-for="template in templates" :key="template.id" :value="template.id">
            {{ template.name }}
          </option>
        </select>
      </div>
    </div>
    <span slot="footer">
      <button class="btn btn-primary" @click="generateList" :disabled="!selectedTemplate">
        Genereren
      </button>
      <a class="btn btn-white" @click="hide">Sluiten</a>
    </span>
  </Modal>
</template>

<script>
import Modal from '@/components/iam/Modal'
import { startLoadingModal, stopLoadingModal, errorModal } from '@/modalMessages'
import { createDocument, getTemplates, pollGutenborg } from '@/services/gutenborgService'
import { poll } from '@/utils/helpers'

export default {
  name: 'CommissionListExportModal',
  components: {
    Modal
  },
  props: {
    templateType: {
      required: true,
      type: Number
    },
    exportData: {
      required: true,
      type: Object
    },
    useCustomListName: {
      type: Boolean,
      default: true
    },
    title: {
      required: false,
      type: String,
      default: 'Lijst genereren'
    },
    defaultListName: {
      required: false,
      type: String,
      default: 'Nieuwe lijst'
    },
    landscape: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      selectedTemplate: 0,
      listName: this.defaultListName,
      templates: []
    }
  },
  created () {
    this.initialize()
  },
  methods: {
    initialize () {
      return getTemplates(this.templateType).then(response => {
        this.templates = response.data.templates
        this.selectedTemplate = this.templates[0]?.id
      }).catch(error => {
        console.error(`Error loading list templates: ${error}`)
        errorModal('Templates konden niet geladen worden.')
      })
    },
    show () {
      this.$refs.modal.show()
    },
    hide () {
      this.$refs.modal.hide()
      this.listName = this.defaultListName
      this.selectedTemplate = this.templates[0]?.id
    },
    async generateList () {
      try {
        startLoadingModal('Je document wordt gegenereerd')
        let mergeData = this.exportData
        mergeData.listName = this.listName
        mergeData = btoa(encodeURIComponent(JSON.stringify(mergeData)))
        console.log(mergeData)
        const payload = {
          merge_data: mergeData,
          template_id: this.selectedTemplate,
          config: {
            type: 'pdf',
            destination: 'temp',
            format: 'B64PDF',
            landscape: this.landscape
          }
        }
        const document = await createDocument(payload)
        const pollResult = await poll(document?.data?.uuid, pollGutenborg, 1000)
        this.hide()
        window.open(pollResult?.url, '_blank')
        stopLoadingModal()
        return pollResult?.url
      } catch (e) {
        console.error(e)
        errorModal('Er ging iets mis bij het genereren van de lijst. Probeer later eens opnieuw.')
      }
    }
  }
}
</script>

<style scoped>

</style>
